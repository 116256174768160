import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const TestimonialListStyles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  width: 100%;
  flex: 0 1 64%;
`;

export const CountContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px 32px;
`;

export const ResultsCount = styled.p`
  ${font('text', 'xl', '600')}
  color: ${colors.gray[600]};
  white-space: nowrap;
  margin: 0;
`;

export const ClearFilters = styled.p`
  ${font('text', 'xl', '400')}
  color: ${colors.primary[500]};
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
`;
